.bg-gradient-main {
  background-image: linear-gradient(310deg, #5c74ff 0%, #2fd89e 100%);
}

svg.text-main .color-foreground {
  fill: #5c74ff;
}

svg.text-main .color-background {
  fill: #2fd89e;
}

.text-main{
  color: #5c74ff !important;
}

.slick-next::before, .slick-prev::before {
  content: none !important;
}

.slick-next, .slick-prev{
  display: block;
  color: #67748e !important;
  opacity: 0.8;
}

.slick-next:hover, .slick-next:focus, .slick-prev:hover, .slick-prev:focus{
  color: #67748e !important;
  opacity: 1;
}

.slick-next.slick-disabled, .slick-prev.slick-disabled{
  opacity: 0.25 !important;
}